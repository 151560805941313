.searchDiv {
    padding: 10px;
    padding-top: 0px;
    border: 1px solid #787878;
    margin-left: 10px;
    margin-right: 10px;
    background: white;
}

/* src/components/YourComponent.css */
.vertical-stack {
    display: flex;
    flex-direction: column; /* Vertical stack */
    /* gap: 20px; Optional gap between form groups */
    padding: 10px; /* Optional padding for overall spacing */
  }
  
  
  
  .full-width {
    width: 100%; /* Full width for the select elements */
    padding: 5px; /* Optional padding for the select elements */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Optional shadow for visual depth */
  }
  
  h5 {
    margin-bottom: 5px;
    color: #333;
  }
  select {
    background-color: #cfcfcf;
    color: #555;
    border-radius: 5px;
  }
  #referenceinput {
    border: 1px solid;
    width: 97%!important;
    border-radius: 5px;
    background-color: #cfcfcf;
    color: #555;
}
 
#boto_buscador{
   
    cursor: pointer;
}