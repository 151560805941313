#root {
  width: 100%!important;
}

.css-1uwl3xt-MuiPaper-root-MuiAppBar-root {
  width: 100%!important;
  left:0!important;
}

.headerBarParent {
    background: rgba(0, 0, 0, 0.466)!important;
    padding: 4px 0px 4px 0px;
    position: fixed!important;
    /* background: rgba(0, 0, 0, 0.541)!important; */
    
  }

 .header-button {
    background: #FF3232!important;
    text-transform: uppercase!important;
    text-shadow: none!important;
    font-size: 0.8em!important;
    color: white!important;
    font-weight: bold!important;
    border-radius: 3px!important;
    margin-right: 5px!important;
    font-family: 'Doppio One'!important;
    padding-top: 7px;
    padding-bottom: 7px;
 } 
 .menuDesktop{
    margin-left:50px;
 }
 .floatLeft {
    align-items: right;
    float: right;
 }
 .right-aligned {
    display: flex;
    justify-content: flex-end; /* Aligns content to the right */
  }
/* 
  .whiteForced {
    color: white !important;
    border-color: white !important;

  }
  .whiteForced svg {
    color: white !important;
  }
  .whiteForced::after{
    color: white !important;
  } */
  .whiteForced::after{
    border: none!important;
  }

  .whiteForced{
    padding-left: 10px;
  }
  .buttonFormOutline {
    background: #ffffffb3;
    padding: 2px!important;
    border-radius: 3px!important;
    padding-left: 10px;
  }
  .marginLeft{
    margin-left: 5px;
    padding: 5px!important;
  }
.buttonFormOutline:hover {
    cursor: pointer;
}
 .MenuIconNoDesktop{
    display: none!important;
 }
 .displayNoneDesktop{
    display: none!important;
 }
 .backgroundgreenandrightFloating{
  background: #FF3232!important;
  color:white!important;
  float: right;
  margin-right: 15px!important;
 }


 @media (max-width: 768px) {

 #forlogomobile {
  width: 100%!important;
  margin-bottom: 10px;
 }

}