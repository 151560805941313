/* src/styles/BusinessStatus.css */
.business-status {
    text-align: center; 
    padding: 10px; 
  }
  
  .business-status span {
    font-size: 1.2em; 
    color: white; 
  }
  