/* General styling for the reviews section */
.review-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  /* Title for the review section */
  .review-title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 30px;
    color: #333;
    text-align: center;
  }
  
  /* Grid container for review cards */
  .review-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 30px;
  }
  
  /* Each individual review card */
  .review-card {
    padding: 25px;
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #eaeaea;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Hover effect for review cards */
  .review-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  }
  
  /* Header for each review, with name and date */
  .review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  
  /* Reviewer's name styling */
  .review-name {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    text-transform: capitalize;
  }
  
  /* Date styling */
  .review-date {
    font-size: 14px;
    color: #999;
    font-style: italic;
  }
  
  /* Verified purchase badge */
  .verified {
    font-size: 12px;
    font-weight: bold;
    color: #28a745;
    margin-bottom: 15px;
    display: inline-block;
  }
  
  /* Main review text */
  .review-text {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
  }
  
  /* Review rating section */
  .review-rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  /* Star rating for the review */
  .stars {
    font-size: 18px;
    color: #f5a623;
    letter-spacing: 2px;
  }
  
  /* Rating score next to stars */
  .rating-score {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  /* Featured review card */
  .featured-review {
    border: 2px solid #ffcc00;
    background-color: #fff9e6;
  }

  


  
  
  .stars {
    font-size: 18px;
    color: #f5a623; /* Gold color for stars */
  }
  
  .rating-score {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }
  

  .rating-summary {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .overall-rating {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .stars {
    font-size: 24px;
    color: #f5a623;
    margin-right: 10px;
  }
  
  .overall-score {
    font-size: 18px;
    color: #333;
  }
  
  .rating-info-link {
    font-size: 12px;
    color: #007bff;
    text-decoration: none;
    margin-bottom: 20px;
    display: inline-block;
  }
  
  .rating-info-link i {
    font-style: normal;
    font-weight: bold;
  }
  
  .rating-breakdown {
    margin-top: 20px;
  }
  
  .rating-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  .rating-bar {
    flex-grow: 1;
    margin: 0 10px;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .filled-bar {
    height: 100%;
    background-color: #f5a623;
  }
  
  .filled-bar[style="width: 0%"] {
    background-color: #e0e0e0;
  }
  
  .rating-item span {
    font-size: 14px;
    color: #333;
  }
  