.place-card .place-card-large{
  display: none!important;
} 

.two-column-layout {
    display: flex;
    flex-wrap: wrap;
  }
  
  .column-left {
    flex: 1;
    max-width: 450px;
    background: #fff;
  }
  
  .column-right {
    flex: 3;
    display: flex;
    flex-direction: column;
    background: #fff;
  }
  
  .vehicle-details {
    display: flex;
    flex-direction: column;
    padding-top:10px;
  }
  
  .vehicle-specifications {
    background: gold;
    margin-bottom: 10px;
    font-weight: bold;
    padding: 5px;
    padding-left: 15px;
    text-align: left;
    text-transform: uppercase;
  }
  
  .sub-column-left, .sub-column-right {
    flex: 1;
    padding: 15px;
  }
  
  .sub-column-left {
    flex: 2;
  }
  
  .sub-column-right {
    flex: 3;
    display: flex;
    flex-direction: column;
  }
  .inlineDisplay{
    display: flex;
  }
  .productH1 {
    color: #313131;
    font-weight: bold;
    padding-bottom: 0px !important;
    margin-bottom: -10px;
    margin-top: -5px;
    font-weight: bold;
    font-family: 'Doppio One';
  }
  .wrapperPretsiWhatsupp{
    display: flex;
    margin-bottom: 10px;
  }
  .column-left{
    width: 45%;
    flex-wrap: wrap;
    display: flex !important;
    align-items: baseline;
  }

   .priceSpan {
    display: block;
    color: #e1ba00;
    font-weight: bold;
    font-size: 35px;
    margin-bottom: 2px;
   }
   .smallTax{
    font-size: 12px !important;
    line-height: 16px;
    margin-left: 5px;
   }
  .whatsuppProductPageButton{
    width: 55%;
    display: flex;
    text-align: center;
    margin: 10px;
    margin-left: 0px;
    gap: 20px;
    cursor: pointer;
  }
  .buttonWithHoverAndWhatsuppApiMessage{
    display: flex;
    gap: 17px;
    align-items: center;
    background: #14ab4c;
    color: white !important;
    border-radius: 8px;
    padding: 10px 20px;
    margin: 0 20px;
    text-decoration: none !important;
    transition: .2s !important;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
  }
  .buttonWithHoverAndWhatsuppApiMessage:hover {
    background: #10853c !important;
    color: white !important;
    transition: .2s !important;
    cursor: pointer;
  }
  .productH2{
    color: #9d9d9d;
    font-size: 22px;
  }
  .body1{
    padding:10px;
    margin-top:5px;
  }
 .makethebestoffer{
    width: 46%!important;
    left: 25%;
    position: relative;
    right: 75%;
    background: #343433 ;

    color: #ffcc00;

    border-radius: 7px;
    font-weight: bold!important;
    padding: 7px;
    font-size: 15px;
    font-weight: bolder;
    text-transform: uppercase;
  }
  #buttonsubmit {
    background: #ffcc00;

    color: #343433;
  }
 #buttonsubmit:hover{
  background: #343433;
  color: #ffcc00!important;
 }
 .makethebestoffer:hover{
  background: #ffcc00;
  color: #343433;
 }
 .css-xn6ruc>:not(style){
  width: 75ch!important;
}
.sizeFont{
  font-size: 12px;
}

 
.forceWidth .width100 {
  width: 100ch !important;
  text-align: justify;
}
  @media (max-width: 768px) {
    .two-column-layout {
      flex-direction: column;
    }
    .column-left{
      margin-top: 100px;
    }
    .column-left  {
      max-width: 100%;width: 100%;
    }
    .column-right {
      max-width: 100%;width: 100%;
    }
    .sub-column-left, .sub-column-right {
      max-width: 100%;
      flex: none;
    }
    
    .nodisplayonmobile{
      display: none!important;
    }
    .mainImageMobil {
      width: 100%!important;
      height: 100%!important;
    }
    .inlineDisplay {
      display: grid;
      
    }
    .column-left{
      display: none!important;
    }
    .vehicle-details{
      margin-top: 114px!important;
      margin-bottom: 100px;
    }
    .whatsuppProductPageButton{
      width: 100%;
    }
    .buttonWithHoverAndWhatsuppApiMessage{
      margin:none!important;
      margin-right:0px!important;
      margin-left:0px!important;
      padding-top: 10px;
    }
    .wrapperPretsiWhatsupp{
      display: contents;
    }
    .productH1, .productH2{
      
      text-align: center;
      
    }
    .productH2{
      margin-top: 20px;
    margin-bottom: 5px;
    }
    .body1{
      padding:5px;
      text-align: left;
      margin-left: 30% !important;
        margin-top: 5px !important;
    }
    .priceSpan{
      
      text-align: center;
    }
    .MuiContainer-root {
      padding-right: 0px!important;
    }
    .descriptionPRoduct{
      padding:10px;
      font-size: 15px;
    }
    .makethebestoffer{
      width: 100%!important;
      left:0;
    }
    
    .forceWidth .width100{
      width: 55ch!important;
    }  .css-xn6ruc>:not(style){
      width: 38ch !important;
    }

  }


  #telephoneID , #emailID , #nameID, #priceID {
    margin-bottom: 5px!important;
  }
  
  .css-wb57ya-MuiFormControl-root-MuiTextField-root{
    padding-bottom: 10px!important;
  }
 
 
/* Hide the default file input */
.custom-file-input {
  display: none;
}

/* Style the custom file input label to look like a button */
.custom-file-label {
  display: inline-block;
  width: 50%;
  height: 26px;
  padding: 16px 14px;
  font-size: 16px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.5;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  transition: border-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
}

.custom-file-label:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.custom-file-label:focus-within {
  border-color: #3f51b5;
  box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2);
}

/* Style for the selected file name text */
.custom-file-name {
  display: block;
  margin-top: 8px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

/* Flex container to align label and input on the same line */
.custom-file-container {
  display: flex;
  align-items: center;
}

