@keyframes buildAnimation {
    0% {
      transform: scaleY(0);
      transform-origin: top;
    }
    100% {
      transform: scaleY(1);
    }
  }
  
  .slide-animation {
    animation: buildAnimation 0.5s ease-in-out;
  }
  
  .openCss {
    position: relative;
    margin-top: -50px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    font-size: 18px;
    
    padding: 15px;
    opacity: 1;
    transition: all .2s ease-out !important;
  }
  .slick-prev, .slick-next{
    display: none!important;
  }


  @media (max-width: 768px) {
    .sliderContainer {
      margin-top: 10px; /* Add margin-top only for mobile devices */
    }

  }