


    .css-xdmu94-MuiContainer-root {
        max-width: 54%;

    }
    



@keyframes bounceIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
      color: black;
    }
    60% {
      transform: scale(1.05);
      opacity: 1;
      color: gold;
    }
    80% {
      transform: scale(0.95);
      color: black;
    }
    100% {
      transform: scale(1);
      opacity: 1;
      color: gold;
    }
  }
  
  .bounce-in {
    animation: bounceIn 3s ease-in-out;
  }
  

  @media (max-width: 768px) {
    .mobildetails {
        margin-top: 40%;
    }
    .asdasdads{
        padding-right: 0px;
    }
    
    .css-xdmu94-MuiContainer-root {
        max-width: 100%!important;
        margin-top: 30%;
    }
    
    }


    @media (max-width: 768px) {
        .asdasdads {
          padding-right: 16px!important;
        }
       
    }